import RejectWithReasonModel from "Component/Modals/RejectWithReasonModel";
import React, { useState } from "react";
import { Form } from "react-bootstrap";

const TableStatusOptionsAction = ({
  value,
  onChange,
  statusOptionArr,
  showRejectModel = false,
}) => {
  const [rejectModelStatus, setRejectModelStatus] = useState(false);
  const [rejectStatus, setRejectStatus] = useState("");

  console.log(rejectModelStatus);
  const rejectSubmitHandler = (reason) => {
    console.log(reason);
    onChange(rejectStatus, reason);
  };
  const hideHandle = () => {
    setRejectModelStatus(false);
  };

  return (
    <>
      <RejectWithReasonModel
        hideHandle={hideHandle}
        setCommissionModel={setRejectModelStatus}
        submitHandler={rejectSubmitHandler}
        // rejectReason={}

        show={rejectModelStatus}
      />
      <Form.Select
        className="form-control rounded-pill w-auto h-auto fw-sbold"
        aria-label="Default select example"
        value={value}
        onChange={(e) => {
          console.log(e.target.value);
          if (["decline", "2"]?.includes(e.target.value) && showRejectModel) {
            setRejectStatus(e.target.value);
            setRejectModelStatus(true);
          } else {
            onChange(e.target.value);
          }
        }}
      >
        {statusOptionArr.map((item) => {
          return <option value={item.value}>{item.label}</option>;
        })}
      </Form.Select>
    </>
  );
};

export default TableStatusOptionsAction;
