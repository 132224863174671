import Fade from "react-reveal";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form } from "reactstrap";

import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import DatePickerField from "Component/common/DatePickerField";
import SearchFilter from "Component/common/SearchFilter";
import TableActions from "Component/common/TableActions";
import TableLayout from "Component/common/TableLayout";
import TableProfile from "Component/common/TableLayout/TableProfile";
import dataHandler from "hooks/dataHandler";
import moment from "moment";
import { CMS_LIST, EVENT_LIST } from "services/ApiCalls";
import Plussvg from "../../../assets/svg/plussvg.svg";
import { truncateString } from "utilities/utilities";
import { useSelector } from "react-redux";

const CMSTable = ({ name }) => {
  const navigate = useNavigate();

  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: CMS_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      name: name ? name : "ABOUT_US",
      // type: 1,
    },
    dependencies: ["name", "type"],
  });

  const permission = useSelector(
    (s) => s?.profileData?.cmsAdminPermission?.isAdd
  );

  const col = [
    {
      head: "Name",
      accessor: "",
      component: (item) => <>{item?.name || ""}</>,
    },

    {
      head: "Created At",
      accessor: "createdAt",
      component: (item) => (
        <>{moment(item?.createdAt).format("YYYY/MM/DD  HH:MM ")}</>
      ),
    },
  ];
  if (permission != null ? permission : true) {
    col.push({
      head: "Action",
      accessor: "",
      component: (item) => (
        <>
          <TableActions
            editLink={`/edit-cms/${item?._id}`}
            // viewLink={`/view-event/${item?._id}`}
          />
        </>
      ),
    });
  }
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="fillter_form_wrap d-flex justify-content-end align-items-center gap-3 ">
              {(permission != null ? permission : true) && (
                <div className="export_btn d-flex align-items-center gap-3">
                  <Button
                    className="plussvg"
                    onClick={() => navigate("/add-cms")}
                  >
                    <img src={Plussvg} alt="" className="img-fluid" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Form>

        <div className="manageuser_tabbingset ">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              {/* <div className="clearfix">
                <input
                  type="radio"
                  name="tab"
                  id="Rising" // Updated ID
                  checked={body?.type == 1}
                  onClick={() => setBody((p) => ({ ...p, type: 1 }))}
                />
                <label htmlFor="Rising">Rising</label>

                <input
                  type="radio"
                  name="tab"
                  id="Professional" // Updated ID
                  checked={body?.type == 2}
                  onClick={() => setBody((p) => ({ ...p, type: 2 }))}
                />
                <label htmlFor="Professional">Professional</label>
                <input
                  type="radio"
                  name="tab"
                  id="Executive" // Updated ID
                  checked={body?.type == 3}
                  onClick={() => setBody((p) => ({ ...p, type: 3 }))}
                />
                <label htmlFor="Executive">Executive</label> */}

                {/* <input
                  type="radio"
                  name="tab"
                  id="QUICK" // Updated ID
                  checked={body?.type == 4}
                  onClick={() => setBody((p) => ({ ...p, type: 4 }))}
                />
                <label htmlFor="QUICK">Quick Register</label> */}
              {/* </div> */}

              <div className="manageuser_tabbingset mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                    <TableLayout
                      data={data}
                      column={col}
                      loader={loader}
                      body={body}
                    />
                    <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default CMSTable;
