import dataHandler from "hooks/dataHandler";
import { useEffect, useRef, useState } from "react";
import { GrGallery } from "react-icons/gr";
import { CHAT_LIST, MESSAGE_LIST } from "services/ApiCalls";
import { useSocket } from "socket/socketProvider";
import {
  catchAsync,
  showMessageSendReceiveTime,
  truncateString,
} from "utilities/utilities";
import MessageList from "./MessageList";
import SendMessageForm from "./SendMessageForm";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormGroup } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import useDebounce from "hooks/useDebounce";
import Loading from "Component/common/Loading";

function ChatSupport() {
  const [supportId, setSupportId] = useState(null);
  const [supportDetails, setSupportDetails] = useState(null);
  const { socket, connectSocket } = useSocket();
  const chatListContainer = useRef();

  const [searchValue, setSearchValue] = useState("");

  const {
    loader,
    data,
    refetch,
    total,
    body: chatListBody,
    setBody: setChatListBody,
  } = dataHandler({
    api: CHAT_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      limit: 10000,
      orderBy: "lastMessageTime",
    },
  });

  const {
    data: messageList,
    setData,
    setBody,
  } = dataHandler({
    api: MESSAGE_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      page: 1,
      limit: 10000,
      order: 1,
    },
    dependencies: ["supportId"],
    DoNotCallApiUntil: ["supportId"],
  });

  const debounceValue = useDebounce(searchValue);

  useEffect(() => {
    setChatListBody((p) => ({
      ...p,
      search: debounceValue,
    }));
  }, [debounceValue]);

  const scrollToBottom = () => {
    if (chatListContainer.current) {
      chatListContainer.current.scrollTop =
        chatListContainer.current.scrollHeight;
    }
  };

  const chatSeenHandler = catchAsync(async () => {
    const userId = localStorage?.getItem("_id");

    socket?.emit(
      "chat-screen",
      {
        userId,
        supportId,
      },
      (data) => {
        console.log(data, "read success");
        if (data?.success) {
          refetch();
        }
      }
    );
  });

  useEffect(() => {
    scrollToBottom();
    chatSeenHandler();
  }, [messageList]);

  useEffect(() => {
    if (socket) {
      socket
        ?.off("receive-support-message")
        ?.on("receive-support-message", (message) => {
          refetch();
          if (message?.supportId === supportId) {
            setData((p) => [...p, message]);
            scrollToBottom();
          }
        });
    }
  }, [socket, supportId]);

  useEffect(() => {
    connectSocket();
  }, []);


  return (
    <>
      <section className="dashboard_first comon_dashpad pt-2">
        <div className="container py-2">
          <div className="resource_headwrap d-flex align-items-center justify-content-between mb-2">
            <h3 className="fillter_head">Manage Support Isssues</h3>
            <FormGroup className="position-relative gap-3 d-flex align-items-center">
          
              <Input
                type="search"
                name="email"
                id="headersearch"
                style={{ minWidth: 300 }}
                placeholder="Search Name or Support Case"
                value={searchValue}
                onChange={(e) => setSearchValue((p) => e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 col-xl-4 mb-4 mb-md-0">
              <h5 className="font-weight-bold mb-3 text-white">Member</h5>

              <div className="card mask-custom">
                <div className="card-body p-1">
                  <ul
                    className="list_scroll list-unstyled mb-0"
                    style={{
                      height: "calc(100vh - 210px)",
                      backgroundColor: "#000",
                    }}
                  >
                    {/* <div
                      className="overflow-auto chat-list-scrollbar"
                      style={{
                        minHeight: "calc(100vh - 200px)",
                        maxHeight: "calc(100vh - 200px)",
                      }}
                      id="scrollableDiv"
                    > */}
                    {/* <InfiniteScroll
                        dataLength={data?.length}
                        next={() => fetchMore()}
                        hasMore={total > data?.length ? true : false}
                        scrollableTarget="scrollableDiv"
                      > */}
                    {data?.length !== 0 ? (
                      <>
                        {data?.map((item, idx) => {
                          return (
                            <li
                              className="p-2 border-bottom"
                              style={
                                supportId == item?._id
                                  ? {
                                      background: "#b0148e",
                                    }
                                  : {
                                      borderBottom:
                                        "1px solid rgba(255,255,255,.3) !important",
                                    }
                              }
                              onClick={() => {
                                setSupportId((p) => item?._id);
                                setBody((p) => ({
                                  ...p,
                                  supportId: item?._id,
                                }));
                                setSupportDetails((p) => item);
                              }}
                            >
                              <a
                                // href="#"
                                className="d-flex justify-content-between link-light align-item-start"
                              >
                                <div
                                  className="d-flex flex-row align-item-start"
                                  style={{
                                    maxWidth: "75%",
                                  }}
                                >
                                  <img
                                    src={item?.userId?.profileImage}
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={40}
                                  />
                                  <div
                                    className="pt-1"
                                    style={{
                                      width: "calc(100% - 48px)",
                                    }}
                                  >
                                    <p className="fw-bold mb-0 text-white text-truncate">
                                      {item?.fullName}
                                    </p>
                                    <p className="custom_font text-white m-0 w-100 text-truncate">
                                      {item.messageType === 3 ? (
                                        <GrGallery />
                                      ) : (
                                        item?.message
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="pt-1">
                                  <p className="custom_font text-white mb-1">
                                    {showMessageSendReceiveTime(
                                      item?.lastMessageTime
                                    )}
                                  </p>
                                  <span className="badge bg-danger float-end">
                                    {item?.unreadCount ? item?.unreadCount : ""}
                                  </span>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </>
                    ) : (
                      <p className="text-center text-white mt-2">
                        No Members Found!
                      </p>
                    )}
                    {loader && <p className="text-center">loading...</p>}
                    {/* </InfiniteScroll>
                    </div> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 col-xl-7 position-relative">
              {supportId ? (
                <>
                  <MessageList
                    supportId={supportId}
                    messageList={messageList}
                    supportDetails={supportDetails}
                    setSupportDetails={setSupportDetails}
                    chatListContainer={chatListContainer}
                    scrollToBottom={scrollToBottom}
                  />
                  {supportDetails?.status === 2 ? (
                    <p className="text-danger text-center">Chat Was Resolved</p>
                  ) : (
                    <SendMessageForm supportId={supportId} socket={socket} />
                  )}
                </>
              ) : (
                <div className=" d-flex justify-content-center align-items-center h-100">
                  <h5 className="text-white">
                    Please Select any Chat to start chatting .
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChatSupport;
